import styled from '@emotion/styled';
import { COLORS3, FONT, SPACING } from '@zola/zola-ui/src/styles/emotion';

export const CardItem = styled.li``;

export const Card = styled.a`
  display: flex;
  gap: ${SPACING.XS};
  padding: 24px;
  border-radius: 16px;
  background-color: ${COLORS3.BLACK_005};
  text-decoration: none;
  height: 100%;
  width: 100%;
  transition: transform 0.1s linear;
  &:hover {
    cursor: pointer;
    text-decoration: none !important;
    transform: scale(1.02);
  }
`;

export const Hed = styled.div`
  font-weight: ${FONT.FONT_WEIGHT_SEMI_BOLD};
  ${FONT.textSizeCompact(FONT.FONT_SIZE_SMALL)}
  margin-bottom: ${SPACING.XS};
`;

export const Dek = styled.div`
  ${FONT.textSizeCompact(FONT.FONT_SIZE_SMALLER)}
  color: ${COLORS3.BLACK_075};
`;

export const CategoryIcon = styled.img`
  height: 24px;
  width: 24px;
`;
