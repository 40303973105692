import ApiService from '@zola-helpers/client/dist/es/http/api';

export type FaqArticleSearchResult = {
  title: string;
  slug: string;
  sectionTitle?: string;
  /** The search term that yeilded the result */
  searchTerm: string;
};

export type FaqSearchQuery = {
  query: string;
  skip?: number;
};

export const searchFaqs = (search: FaqSearchQuery): Promise<FaqArticleSearchResult[]> => {
  const { query, skip = 0 } = search;
  const queryString = `?query=${query}&skip=${skip}`;
  return ApiService.get<FaqArticleSearchResult[]>(
    `/web-preauth-api/v1/faqs/search-articles${queryString}`
  );
};

export type FaqPaginatedSearchResults = {
  items: {
    body: string;
    title: string;
    slug: string;
  }[];
  skip: number;
  total: number;
  query: string;
};

export const searchFaqsFullText = (search: FaqSearchQuery): Promise<FaqPaginatedSearchResults> => {
  const { query, skip = 0 } = search;
  const queryString = `?query=${query}&skip=${skip}`;
  return ApiService.get<FaqPaginatedSearchResults>(`/web-preauth-api/v1/faqs/search${queryString}`);
};
