import { FaqCategory } from 'libs/contentful/faqs/types';
import React from 'react';

import { CardItem, Hed, Dek, CategoryIcon, Card } from './FaqCategoryCard.styles';

export type FaqCategoryCardProps = {
  className?: string;
  category: FaqCategory;
};

const FaqCategoryCard = ({ className, category }: FaqCategoryCardProps): JSX.Element => {
  const { title, description, icon, slug } = category;
  const { src } = icon || {};
  return (
    <CardItem className={className}>
      <Card href={`/faq/category/${slug}`}>
        <CategoryIcon src={src} alt="" />
        <div>
          <Hed>{title}</Hed>
          <Dek>{description}</Dek>
        </div>
      </Card>
    </CardItem>
  );
};

export default FaqCategoryCard;
