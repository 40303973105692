import styled from '@emotion/styled';
import COLORS3 from '@zola/zola-ui/src/styles/emotion/colors3';
import FONT from '@zola/zola-ui/src/styles/emotion/fonts';
import SPACING from '@zola/zola-ui/src/styles/emotion/spacing';
import MEDIA_QUERY_V2 from '@zola/zola-ui/src/styles/emotion/mediaQueryV2';
import H from '@zola/zola-ui/src/typography/Headings';

export const Container = styled.div`
  position: relative;
`;

export const ImageContainer = styled.div`
  overflow: hidden;
  width: 100%;
  height: 100%;

  ${MEDIA_QUERY_V2.MOBILE} {
    min-height: 360px;
  }

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: ${COLORS3.BLACK_100};
    opacity: 0.2;
  }
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const ContentContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: ${SPACING.S24};
  text-align: left;
  ${MEDIA_QUERY_V2.EXCLUDE_MOBILE} {
    align-items: center;
  }
`;

export const Hed = styled(H.TitleAccent1)`
  ${FONT.textSizeCompact(FONT.FONT_SIZE_LARGER)};
  margin: 0 0 ${SPACING.S24};
`;

export const Sub = styled.div`
  ${FONT.textSizeCompact(FONT.FONT_SIZE_SMALL)};
  margin-bottom: ${SPACING.S8};
  color: ${COLORS3.WHITE_100} !important;
`;
