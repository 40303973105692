import React from 'react';
import { DESKTOP_MIN, TABLET_MIN } from '@zola-helpers/client/dist/es/util/responsive';

import { LinkV2 } from '@zola/zola-ui/src/components/LinkV2';

import {
  Container,
  Image,
  ImageContainer,
  ContentContainer,
  Sub,
  Hed,
} from './FAQSearchHero.styles';
import desktop from '../../../../public/static-preauth/images/Faq/hero/faq-hero-desktop-2024.webp';
import tablet from '../../../../public/static-preauth/images/Faq/hero/faq-hero-tablet-2024.webp';
import mobile from '../../../../public/static-preauth/images/Faq/hero/faq-hero-mobile-2024.webp';
import FaqSearchDropdown from '../FaqSearchDropdown';

export type FAQSearchHeroProps = {
  className?: string;
};

const FAQSearchHero = ({ className }: FAQSearchHeroProps): JSX.Element => (
  <Container className={className}>
    <ImageContainer>
      <picture>
        <source srcSet={desktop} media={`(min-width: ${DESKTOP_MIN}px)`} />
        <source srcSet={tablet} media={`(min-width: ${TABLET_MIN}px)`} />
        <Image src={mobile} alt="" />
      </picture>
    </ImageContainer>
    <ContentContainer>
      <Hed color="WHITE_100" presentation="h3">
        Frequently Asked Questions
      </Hed>
      <FaqSearchDropdown />
      <Sub>
        Looking for a Zola couple?{' '}
        <LinkV2 href="/search/wedding-registry" variant="tertiary" inline subtle>
          Click here
        </LinkV2>
      </Sub>
      <Sub>
        Still have questions?{' '}
        <LinkV2 href="/contact-us" variant="tertiary" inline subtle>
          Email us
        </LinkV2>{' '}
        or reach out at 1-408-657-ZOLA.
      </Sub>
    </ContentContainer>
  </Container>
);

export default FAQSearchHero;
