import styled from '@emotion/styled';
import { MEDIA_QUERY, SPACING } from '@zola/zola-ui/src/styles/emotion';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const CategoriesList = styled.ul`
  display: grid;
  margin: ${SPACING.S24};
  grid-template-columns: 1fr;
  grid-gap: ${SPACING.S24};
  ${MEDIA_QUERY.TABLET} {
    margin: 56px;
    grid-gap: ${SPACING.S16};
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: minmax(auto);
    grid-gap: ${SPACING.S24};
  }
  ${MEDIA_QUERY.DESKTOP} {
    margin: ${SPACING.LG} auto;
    max-width: 928px;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: minmax(auto);
    grid-gap: ${SPACING.S24};
  }
`;
